import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
        a:{
            textDecoration: 'none',
            color: 'white',
        },
        button:{
            background: 'linear-gradient(45deg, #FE6B8B 10%, #FF8E53 80%)',
            border: 0,
            fontSize: 16,
            borderRadius: 3,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            height: 48,
        p:{
            fontFamily: 'Helvetica Neue, sans-serif, serif',
        },

        }
    },
    root: {
        padding: theme.spacing(5, 0, 5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },

    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },


    card: {
        boxSizing: 'border-box',
        color: 'inherit',
        height:'100%',
        maxWidth: '100%',
        whiteSpace: 'normal',
        backgroundColor: 'rgba(10,15,15,0.7)',
        margin: '0rem 2rem 0rem 2rem ',
        padding: theme.spacing(7),
        [theme.breakpoints.down('xs')]: {
            margin:theme.spacing(0),
            padding: theme.spacing(1),
        }
    },
    priceCard:{
        width:'100%',
        display:'block',
        padding: theme.spacing(0),
        backgroundColor: 'rgba(220,220,220,1)',
        borderRadius: 0,
    },
    cardHeader: {
        border: 0,
        boxShadow: '0 1px 0px 0px rgba(25, 25, 25, .1)',

    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(1),
    },
    cardPricingText: {
        fontSize:'1.2rem',
    },
    media: {
        height: 500,
    },
    paragraph: {
        textAlign: 'center',
        margin: '1rem',
        fontSize: '1vw',
        lineHeight:'2rem',
        color: '#f2f2f2',
        [theme.breakpoints.down('sm')]: {
            lineHeight:'2rem',
            letterSpacing:'.01rem',
            fontSize: '2vw',
        },
        [theme.breakpoints.down('xs')]: {
            lineHeight:'1.5rem',
            margin: '0rem',
            letterSpacing:'0.11rem',
            fontSize: '3vw',

        },


    },
    contentTitle: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        color: '#f2f2f2',
        textShadow: '1px 1px 2px #080705',
        margin: theme.spacing(3),
        textAlign:'center',
        [theme.breakpoints.down('xs')]: {
            lineHeight:'2rem',
            letterSpacing:'.1rem',
            margin: theme.spacing(1),
            fontSize: '1rem',
        },
    },
    content: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.5rem',
        margin: theme.spacing(3),
        // padding: '1rem',
        justifyContent: 'center',
        fontWeight: 'normal',
        color: '#f2f2f2',
        textShadow: '1px 1px 2px #080705',
        [theme.breakpoints.down('xs')]: {
            lineHeight:'2rem',
            letterSpacing:'.01rem',
            fontSize: '2vh',
        },

    },


}));


export default function Lesson() {
    const classes = useStyles();

    const tiers = [
        {
            title: '体験レッスン',
            subheader: '1回のみの体験レッスン',
            price: '1,500円(税込)',
            description: ['※1コマ45分',],
        },
        {
            title: ' 通常レッスン',
            subheader: '月最大5回まで通い放題のレッスン',
            price: '10000円/月',
            description: ['※1コマ30分',],
        }
    ];
    //スタジオ代別途(1200円前後/1h)
    return (
        <Layout bgImage={true} siteTitle={'Lesson'}>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Lesson
                </Typography>

                <Card className={classes.card}>
                    {/*<CardMedia*/}
                    {/*    className={classes.media}*/}
                    {/*    image={data.placeholderImage.childImageSharp.fluid.src}*/}
                    {/*    title="Contemplative Reptile"*/}
                    {/*/>*/}
                    <CardContent className={classes.cardPricing}>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={12}  md={12}>
                                <Typography component="h1" className={classes.contentTitle} variant="h3">
                                    女性限定
                                </Typography>
                                <Typography component="h1" className={classes.contentTitle} variant="h3">
                                アコースティックギターレッスン生募集
                                </Typography>
                                <ul>
                                <Typography component="li" className={classes.paragraph}>
                                    都内リハーサルスタジオにて基本的にマンツーマンにて行います。
                                    ギターの構え方、弦の張り方など初歩的なところから、コード、アルペジオ、バッキングなど技術的なところまで、
                                    優しくていねいに、わかりやすく受講者の方が楽しんで学べるように指導します。
                                    アコギをお持ちでない方でも楽器のレンタルが出来るため、安心してレッスンを受けていただくことが可能です。
                                    女性アコースティックギタリストだからこそ女性に伝えられるレッスンを提供いたします。
                                </Typography>
                                </ul>
                            </Grid>
                            {tiers.map((tier) => (
                                <Grid item key={tier.title} xs={12} sm={12} md={6}>
                                    <Card className={classes.priceCard}>
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{ align: 'center' }}
                                            subheaderTypographyProps={{ align: 'center' }}
                                            className={classes.cardHeader}
                                        />
                                        <CardContent>
                                            <div className={classes.cardPricing}>
                                                <Typography component="h2" variant="h3" className={classes.cardPricingText}>
                                                    {tier.price}
                                                </Typography>
                                            </div>
                                            <ul>
                                                {tier.description.map((line) => (
                                                    <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                        {line}
                                                    </Typography>
                                                ))}
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                            <Grid item xs={12}  md={12} style={{textAlign:'center'}}>
                                <Typography component="h1"　align="center" className={classes.content} variant="p">
                                    お問い合わせは以下のフォームにてお問い合わせください。
                                </Typography>
                                    <Button variant="contained">
                                        <Link to="/contact">
                                        お問い合わせフォームへ
                                        </Link>
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </Layout>
    )
};
